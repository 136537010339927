import { graphql } from 'gatsby';
import React from 'react';

import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import TermsConditionsInfo from '../components/termsConditions/termConditionsInfo';
import { PageBase } from '../context/global-context';
import { PageContext } from '../types/page';
import { TermsConditionsData } from '../types/termsConditions';
import { useI18n } from '../utils/i18n';

export const query = graphql`
  query TermsAndConditions($locale: CreeGraphql_I18NLocaleCode) {
    creegraphql {
      termsAndConditionsPage(locale: $locale) {
        data {
          attributes {
            locale
            title
            body
            slug
            localizations {
              data {
                attributes {
                  slug
                  locale
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TermsConditions: React.FC<{
  pageContext: PageContext;
  data: {
    creegraphql: {
      termsAndConditionsPage: {
        data: TermsConditionsData;
      };
    };
  };
}> = ({ pageContext, data }) => {
  const { translations } = pageContext;

  const i18n = useI18n(translations);

  const page = data.creegraphql.termsAndConditionsPage;

  const { title, body } = page.data.attributes;

  return (
    <PageBase translations={translations} instance={page.data}>
      <Layout>
        <Banner pageType={pageType.page} bannerType="common" title={title} />
        <main>
          <TermsConditionsInfo title={title} body={body} />
        </main>
      </Layout>
    </PageBase>
  );
};

export default TermsConditions;
