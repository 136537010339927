import MarkdownIt from 'markdown-it';
import React from 'react';

import './termsConditionsInfo.scss';

const TermsConditionsInfo: React.FC<{ title: string; body: string }> = ({
  title,
  body,
}) => {
  const md = MarkdownIt();

  return (
    <div className="terms-conditions-info">
      <h3>{title}</h3>
      {body && (
        <div dangerouslySetInnerHTML={{ __html: md.render(body) }}></div>
      )}
    </div>
  );
};

export default TermsConditionsInfo;
